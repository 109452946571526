import { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react'
import {
  Button,
  RadioOld,
  RadioGroupOld,
  MetalQualityDropdown,
  FancySelect,
  FancySelectOption,
  StoneShapeFilterOptions,
  SearchBySeriesNumber
} from '@web/shared-ui-components'
import { useSvg } from '@web/shared-util-hooks'
import { AddAHeadFilterKey, SearchType } from './types'

interface AddAHeadFilterOptionsProps {
  metalQuality: Pick<FilterOptionProps, 'options' | 'selectedValue'>
  stoneShape: Pick<FilterOptionProps, 'options' | 'selectedValue'>
  stoneSize: Pick<FilterOptionProps, 'options' | 'selectedValue'>
  productState: Pick<FilterOptionProps, 'options' | 'selectedValue'>
  prongCount: Pick<FilterOptionProps, 'options' | 'selectedValue'>
  series: string | null
  onFilterChange: (filterKey: AddAHeadFilterKey, value: string | null) => void
  onResetFilters: (atoModelNumber: string, productId: string | null, iterationId: string | null, excludedIterationIds: string[] | null) => void
  onSearchTypeChange: (searchType: SearchType) => void
  searchType: SearchType
  atoModelNumber: string
  productId: string | null
  loadingOptions: boolean
  iterationId: string | null
  excludedIterationIds: string[] | null
}

interface FilterOptionProps {
  filterKey: AddAHeadFilterKey
  options: Array<string | null>
  selectedValue: string | null
  onChange: (filterKey: AddAHeadFilterKey, value: any) => void
}

function FilterOptions ({ filterKey, options, selectedValue, onChange }: FilterOptionProps): ReactElement | null {
  const [selectedOption, setSelectedOption] = useState<FancySelectOption | null>(null)

  useEffect(() => {
    setSelectedOption(selectedValue != null ? { value: selectedValue, label: selectedValue } : null)
  }, [selectedValue])

  function handleOnChange (newValue: FancySelectOption | null, actionMeta): void {
    if (newValue != null && actionMeta.action === 'select-option') {
      onChange(filterKey, newValue.value)
    } else if (newValue == null && actionMeta.action === 'clear') {
      onChange(filterKey, null)
    }
  }

  return (
    <FancySelect
      value={selectedOption}
      options={options.filter((option) => option != null).map((option) => ({ value: option, label: option })) as FancySelectOption[]}
      isClearable
      isSearchable={false}
      color='secondary'
      onChange={(newValue, actionMeta) => handleOnChange(newValue as FancySelectOption | null, actionMeta)}
      menuPortalTarget={null}
      styles={({ menuPortal: base => ({ ...base, zIndex: 9999 }) })}
    />
  )
}

function AddAHeadFilterOptions ({
  metalQuality,
  stoneShape,
  stoneSize,
  productState,
  prongCount,
  series,
  onFilterChange,
  onResetFilters,
  onSearchTypeChange,
  searchType,
  atoModelNumber,
  productId,
  loadingOptions,
  iterationId,
  excludedIterationIds
}: AddAHeadFilterOptionsProps): ReactElement | null {
  const handleChange = (filterKey: AddAHeadFilterKey, value: string | null): void => {
    onFilterChange(filterKey, value)
  }

  const addAHeadOptions = [
    { label: 'Filter Options', checked: searchType === 'filter', containerClassName: 'position-relative mr-md-4 p-1 p-md-0 mr-3', value: 'filter' },
    { label: 'Search by Series #', checked: searchType === 'series', containerClassName: 'position-relative p-1 p-md-0', value: 'series' }
  ]

  const [addSvg] = useSvg()

  useEffect(() => {
    addSvg('https://www.stuller.com/htmltemplates/shared/stone-shape-icons-svg.html')
  })

  const handleSearchTypeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onSearchTypeChange(event.target.value as SearchType)
  }

  const handleSeriesSearch = (seriesNumber: string): void => {
    onFilterChange('series', seriesNumber)
  }

  const handleResetFilters = (): void => {
    onResetFilters(atoModelNumber ?? '', productId ?? null, iterationId ?? null, excludedIterationIds ?? [])
  }

  const showFilters = useMemo(() => searchType === 'filter' || series != null, [searchType, series])

  return (
    <div className='u-border-radius-medium c-bg-gray-lt-3 p-4 mt-4'>
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
        <RadioGroupOld
          containerClassName='mb-0'
          className='add-a-head-filter-radio-group d-flex align-items-center ml-0 pl-0'
        >
          {addAHeadOptions.map((option) => (
            <RadioOld
              containerClassName={option.containerClassName}
              key={option.label}
              id={option.label}
              name='add-a-head-search-type'
              value={option.value}
              checked={option.checked}
              onChange={handleSearchTypeChange}
              data-test={option.label}
            >
              <label className='mb-0 text-nowrap' htmlFor={option.label}>
                {option.label}
              </label>
            </RadioOld>
          ))}
        </RadioGroupOld>
        <div className='d-none d-sm-block mb-0 text-right ml-auto'>
          <Button className='sbtn-micro text-nowrap' color='secondary' data-test='reset-selection-button' onClick={handleResetFilters}>Reset Selections</Button>
        </div>
      </div>
      {searchType === 'series' &&
        <div className='u-flex-grid-row'>
          <div className='u-flex-grid-col-lg-4 col-12 mt-3'>
            <label className='font-weight-bold d-block' data-test='search-by-series-label'>Search By Series</label>
            <SearchBySeriesNumber atoModelNumber={atoModelNumber} productId={productId} iterationId={iterationId} excludedIterationIds={excludedIterationIds} onSeriesNumberSearch={handleSeriesSearch} selectedSeries={series} shouldFilterOnIteration useIterationsSystemSetting />
          </div>
        </div>}
      {showFilters &&
        <div className='u-flex-grid-row'>
          <div className='u-flex-grid-col-lg-3 u-flex-grid-col-md-5 u-flex-grid-col-sm-6 mt-3 pr-sm-0'>
            <label className='font-weight-bold d-block'>Metal Quality</label>
            <MetalQualityDropdown
              metalQualityCodes={metalQuality.options}
              selectedValue={metalQuality.selectedValue}
              onSelect={(value) => handleChange('metalQuality', value)}
            />
          </div>
          <div className='u-flex-grid-col-lg-3 u-flex-grid-col-md-5 u-flex-grid-col-sm-6 mt-4 mt-sm-3 pr-sm-4 pr-md-0'>
            <label className='font-weight-bold d-block'>Stone Shape</label>
            <StoneShapeFilterOptions
              options={stoneShape.options}
              selectedValue={stoneShape.selectedValue}
              onSelect={(value) => handleChange('stoneShape', value)}
            />
          </div>
          <div className='u-flex-grid-col-lg-2 u-flex-grid-col-md-3 u-flex-grid-col-sm-4 mt-4 mt-lg-3  pr-sm-0 '>
            <label className='font-weight-bold d-block'>Stone Size (mm)</label>
            <FilterOptions
              filterKey='stoneSize'
              options={stoneSize.options}
              selectedValue={stoneSize.selectedValue}
              onChange={handleChange}
            />
          </div>
          <div className='u-flex-grid-col-lg-2 u-flex-grid-col-md-3 u-flex-grid-col-sm-4 mt-4 mt-lg-3 pr-sm-0'>
            <label className='font-weight-bold d-block'>Product State</label>
            <FilterOptions
              filterKey='productState'
              options={productState.options}
              selectedValue={productState.selectedValue}
              onChange={handleChange}
            />
          </div>
          <div className='u-flex-grid-col-lg-2 u-flex-grid-col-md-3 u-flex-grid-col-sm-4 mt-4 mt-lg-3'>
            <label className='font-weight-bold d-block'>Prong Count</label>
            <FilterOptions
              filterKey='prongCount'
              options={prongCount.options}
              selectedValue={prongCount.selectedValue}
              onChange={handleChange}
            />
          </div>
          <div className='u-flex-grid-col-lg-2 u-flex-grid-col-md-3 u-flex-grid-col-sm-4 mt-4 mt-sm-3 d-flex justify-content-center d-sm-none'>
            <Button color='secondary' onClick={handleResetFilters}>Reset Selections</Button>
          </div>
        </div>}
    </div>
  )
}

export {
  AddAHeadFilterOptions
}
